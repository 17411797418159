// Import our CSS
import styles from '@/css/app.pcss';

import { createApp } from 'vue';

// Import components
import SiteMenu from '../vue/SiteMenu.vue';
import HomeHero from '../vue/HomeHero.vue';
import Gallery from '../vue/Gallery.vue';
import PerfVideo from '../vue/PerfVideo.vue';

const main = () => {
    // Create our vue instance
    const app = createApp({
        components: {
            'site-menu': SiteMenu,
            'home-hero': HomeHero,
            'gallery': Gallery,
            'perf-video': PerfVideo,
        },
    });

    app.mount("#page-container");

    return app;
};

// Execute function
let app = main();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
